import { DialogService } from './dialog.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { EntityManager, EntityQuery, DataService } from '@cime/breeze-client';
import { TranslateService } from '@ngx-translate/core';
import { ToastyService } from '@cime/ngx-toasty';
import { BreezeService } from '@common/services/breeze.service';
import { ViewMode } from '@common/models/view-mode';
import { UserService } from './user.service';
import { ConsoleWindowService } from './console-window.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment.base';

@Injectable()
export class BreezeViewService {
    entityManager: EntityManager;

    constructor(
        entityManager: EntityManager,
        public toastyService: ToastyService,
        public translateService: TranslateService,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public breezeService: BreezeService,
        public userService: UserService,
        public consoleWindowService: ConsoleWindowService,
        public dialogService: DialogService,
        private httpClient: HttpClient
    ) {
        this.entityManager = entityManager.createEmptyCopy();
    }

    isViewMode() {
        return this.activatedRoute.snapshot.data.mode === ViewMode.view;
    }

    isEditMode() {
        return !this.isViewMode();
    }

    handleCommand(commandName, data) {
        return this.httpClient.post<any>('api:///command/' + commandName, data)
            .toPromise()
            .then((result) => {
                this.toastyService.success(this.translateService.instant('Success'));
                return result;
            })
            .catch(e => {
                console.log(e);
                this.toastyService.error(this.translateService.instant('An error occured while saving data'));
                throw e;
            });
    }

    handleQuery(queryName: string, postData: any) {
        const query = new EntityQuery(queryName)
            .using(this.entityManager)
            .withParameters({
                $method: 'POST',
                $data: postData
            });
        return query.execute()
            .then((response) => {
                return response.results;
            })
            .catch(e => {
                this.toastyService.error(this.translateService.instant('An error occured while saving data'));
                throw e;
            });
    }

    executeCommand(commandName: string, data: any, tracking: boolean = true) {
        let query = EntityQuery.from(commandName)
            .using(this.entityManager)
            .withParameters({
                $method: 'POST',
                $data: data
            });
        if (!tracking) {
            query = query.noTracking();
        }

        query.dataService = new DataService({
            serviceName: environment.apiUrl + '/command/',
            hasServerMetadata: false
        });

        // TODO: handle errors
        return query.execute();
    }

}
